/* Custom script for DisneyHolidays.xx framework v2.0.0 */
/* Includes all brands (WDW/DLP/DCL) and all devices (mobile/desktop) */
/* Author: Tom Nelson/Lindsay Prahalad, October 2015 */

// -------------------------------------------------------------------------------------------------------------------

// Global variables

// Setup billboard on portfolio/brand homepages or content pages that use this e.g. WDW Ticket Store
var billboard;
var ytPlayers = [];

var constants = {
    get date() {
        return moment().format('MMMM Do YYYY, h:mm:ss a')
    },
    phone: typeof phone !== 'undefined' ? phone : '',
}

var literal = function (str, values) {
    var allVariables = _.assignInWith(constants, values)
    return _.template(_.get(literalStrings,str))(allVariables);
}

var getJsPath = function() {
    return '/cogs/js/';
}

// -------------------------------------------------------------------------------------------------------------------

// Global functions

// jQuery plugin to check if tag has attribute
// Important! Call with jQuery(this).hasAttr not $(this).hasAttr to avoid clash with Adobe Analytics
$.fn.hasAttr = function (name) { return this.attr(name) !== undefined; };

// Fix body to prevent scrolling (off canvas, modal, etc)
function fixBody(state) { // Pass true/false
    if (state) { $('body').addClass('no-scroll'); }
    else { $('body').removeClass('no-scroll'); }
}

// Log device orientation in GA; include in shared framework to run on tablets which are served the 'desktop' framework
function logOrientation() {
    // Determine device orientation
    var deviceOrientation = ($(window).width() > $(window).height()) ? 'Landscape' : 'Portrait';
    GA.sendEvent( 'Device', 'Orientation', deviceOrientation);
}

// Select Extras
if ($('#extras').length) {
    // Track Booker opt-in or opt-out in Google Analytics
    $('#earm').change(function () {
        var gaValue;
        if ($(this).prop('checked') == true) { gaValue = 'Opt-In'; }
        else { gaValue = 'Opt-Out'; }
        GA.sendEvent( 'Marketing', 'MyMagic+', gaValue);
    });

    if (typeof flightAPI !== 'undefined' && flightAPI !== 'EasyJet') {
        $('#page input[type="submit"]').click(function (event) {
            event.preventDefault();
            intermission();
            form = $('#form');
            delay = 500;
            submitForm();
        });
    }
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Homepage Billboard

// Run billboard with device-specific parameters
var startBillboard = function () {
    // Get slick library
    fetchSlick(function () {
        // Set parameters
        var billboardSettings = {
            autoplay: true,
            autoplaySpeed: 7000
        };

        // Add extra properties for desktop
        if (desktop) {
            billboardSettings.pauseOnHover = false;
            billboardSettings.fade = true;
            // billboardSettings.dots = true; // Disable dots until launch
        }

        if (mobile && $('.billboard').hasClass('new-billboards')) {
            billboardSettings.dots = true;
            billboardSettings.arrows = false;
        }
        // Initialise slick
        // TODO: catch when this is not a function?
        billboard.on('init', function(){
            let previousSlide = 0;
            onBillboardImpression(0);
            $('.slick-arrow').on('click', function() {
                onBillboardNavigation(previousSlide);
            });
            $('.billboard').on('swipe', function() {
                onBillboardNavigation(previousSlide);
            });
            $('.billboard .slick-slide').on('click', onBillboardClick);
            $('.billboard').on('afterChange', function(event, slick, currentSlide) {
                onBillboardImpression();
                previousSlide = currentSlide;
            })

            $(window).scroll(function(){
                const isBillboardVisible  = $(window).scrollTop() < $(".billboard").offset().top + ($(".billboard").outerHeight() / 2);
                const isBillboardPaused = billboard.slick('getSlick').paused;

                if (!isBillboardVisible && !isBillboardPaused) {
                    billboard.slick('slickPause');
                } else if (isBillboardVisible && isBillboardPaused && !$('#quick-quote').hasClass('open')) {
                    billboard.slick('slickPlay');
                }
            });
        })



        billboard.slick(billboardSettings);
    });
};

const getBillboardMetadata = (index) => {
    index = index >= 0 ? index : billboard.slick('slickCurrentSlide');

    var billboardFrame = $('a.slick-slide[data-slick-index=' + index + ']');
    var billboarHeadline = $(billboardFrame).attr('data-billboard-headline');
    var backgroundImage;
    if (billboarHeadline) {
        billboarHeadline = billboarHeadline.trim().substring(0,50);
    }
    if (desktop) {
        backgroundImage = billboardFrame.css('background-image');
    } else {
        backgroundImage = billboardFrame.find('.frame').css('background-image');
    }
    if (backgroundImage) {
        backgroundImage = backgroundImage.substring(backgroundImage.lastIndexOf('/') + 1)
        backgroundImage = backgroundImage.substring(0, backgroundImage.lastIndexOf('.jpg')).substring(0,50)
    }
    return  (index+1) + '|' + billboarHeadline + '|' + backgroundImage;
}
const onBillboardNavigation = (previousSlide) => {
    const previousNavigationData = getBillboardMetadata(previousSlide);
    GA.sendEvent('HPB','Nav',previousNavigationData )
}
const onBillboardClick = () => {
    const navigationData = getBillboardMetadata();
    GA.sendEvent('HPB','Clk',navigationData)
}
const onBillboardImpression = (index) => {
    const navigationData = getBillboardMetadata(index);
    GA.sendEvent('HPB','Imp',navigationData)
}
$('.billboard-show-info-trigger, .billboard-footer-terms').click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    if ($(this).attr('data-warning')) {
        var message = $(this).attr('data-warning').split('~');
        if (message[1] == undefined) {
            message[1] = message[0];
            message[0] = "Terms Apply";
        }          
        warning(message[0], message[1]);
    }
})

// -------------------------------------------------------------------------------------------------------------------

// Billboard on content pages e.g. WDW Ticket Store

// Run billboard with device-specific parameters
var startBillboardOnContentPage = function() {
    // Get slick library
    $.getScript(getJsPath() + "libs/slick-1.6.0.min.js", function() {
        // Set parameters
        var billboardSettings = {
            autoplay: true,
            autoplaySpeed: 5000,
            fade: true
        };

        // Add extra properties for desktop
        if(desktop) {
            billboardSettings.pauseOnHover = false;
        }

        var prevSpeed;
        var prevPaused;
        var setCustomValues = function(event, slick, currentSlide) {

            var customSpeed = slick.$slides[currentSlide || 0].getAttribute('custom-speed');
            var customPause = slick.$slides[currentSlide || 0].getAttribute('custom-pause');
            if (slick.options.autoplaySpeed !== customSpeed) {
                if (customSpeed) {
                    prevSpeed = slick.options.autoplaySpeed;
                    slick.setOption('autoplaySpeed', customSpeed);
                } else {
                    slick.setOption('autoplaySpeed', prevSpeed);
                }
            }
            if (customPause) {
                setTimeout( () => slick.slickPause(), 20)
                prevPaused = true;
            } else {
                prevPaused = false;
                setTimeout( () => slick.slickPlay(), 20)
            }
        }
        billboard.on('afterChange', setCustomValues);
        billboard.on('init', setCustomValues);
        billboard.slick(billboardSettings);
    });
};

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Analytics (Google Analytics and Adobe Analytics, e.g. Omniture SiteCatalyst)

// Manage and track outbound clicks if <a> points to external domain
function outboundClick(clicked, blank) {
    // Popup links (e.g. social buttons) automatically launch a pop up window, so suppress function; clicks are tracked via _trackSocial
    if (clicked.hasClass('popup')) { return; }
    // Get destination URL
    var url = clicked.attr('href');
    // Track clicks to external domains in GA
    externalDomain(url);
    // Delay by 0.25 seconds to allow GAQ to fire if same window
    if (!blank) { setTimeout(function () { window.open(url, '_parent'); }, 250); };
};

// -------------------------------------------

// Log clicks to external domains in Google Analytics
function externalDomain(domain) {
    // Setup outbound domain
    var outbound
    // Check destination domain and override value for GA, if necessary (e.g. DCL affiliate links)
    if (domain.match('disneyworld.disney.go.com|disneybeachresorts.com')) { outbound = 'disneyworld.co.uk' }
    if (domain.match('disneycruise.disney.go.com')) { outbound = 'disneycruise.co.uk' }
    if (domain.match('holidayextras.co.uk')) { outbound = 'holidayextras.co.uk' }
    if (domain.match('thetrainline.com')) { outbound = 'thetrainline.com' }
    if (domain.match('nationalexpress.com')) { outbound = 'nationalexpress.com' }
    // If domain is not matched then use regex to get base URL
    if (!outbound) { if (domain.indexOf("://") > -1) { outbound = domain.split('/')[2]; } }
    // Google Analytics: track outbound clicks
    if (outbound) { GA.sendEvent( 'Outbound Link', outbound, domain); }
}

// -------------------------------------------

// Google Analytics: events for errors
function logErrors() {
    if ($('.event-expired').length) { GA.sendEvent( 'Book', 'Error', 'Timeout'); }; // Timeout
    if ($('.event-hotel').length) { GA.sendEvent( 'Book', 'Error', 'Hotel Unavailable'); }; // No hotel availability
    if ($('.event-eurostar').length) { GA.sendEvent( 'Book', 'Error', 'Eurostar Unavailable'); }; // No Eurostar availability
    if ($('.event-flights').length) { GA.sendEvent( 'Book', 'Error', 'Flights Unavailable'); }; // No flight availability
    if ($('.event-selfdrive').length) { GA.sendEvent( 'Book', 'Error', 'Self Drive Unavailable'); }; // No self drive availability
    if ($('.event-seats').length) { GA.sendEvent( 'Book', 'Error', 'Seats Unavailable'); }; // No seat availability (Gold Medal second call)
    if ($('.event-outofrange').length) { GA.sendEvent( 'Book', 'Error', 'Flights Out of Range'); }; // Flights are out of range (11 months+)
    if ($('.event-noallocations').length) { GA.sendEvent('Book', 'Error', 'No Allocation Flights'); }; // Allocations unavailable (11 months+)
    if ($('.event-noflights').length) { GA.sendEvent('Book', 'Error', 'No Flights (11+ Months)'); }; // Flights are out of range (11 months+)
    if ($('.event-booking').length) { GA.sendEvent( 'Book', 'Error', 'Booking Failure'); }; // Booking fails (payment or transport availabilty)
    if ($('.event-ticketavailability').length) { GA.sendEvent('Book', 'Error', 'No ticket availability'); }; //No ticket availability
    if ($('.event-parkcapacity').length) { GA.sendEvent('Book', 'Error', 'No park capacity'); }; //No park capacity
    if ($('.event-onlysevendayticketavailable').length) { GA.sendEvent('Book', 'Error', 'Only seven-day tickets available'); }; //Only seven-day tickets are available


    if ($('.exception').length) { GA.sendEvent('Book', 'Error', 'Exception'); }; // Search fails (exception)
}

// -------------------------------------------

// Google Analytics: track clicks on launch panel button
$('.launch a.url').click(function (event) {
    // Prevent default to track click in GA
    event.preventDefault();
    // Get data
    var href = $(this).attr('href');
    var action = $(this).attr('data-action');
    // Determine if parent is section or aside
    var parent; if ($(this).parents('section').length) { parent = 'Body' } else { parent = 'Sidebar' };
    // Track in GA
    GA.sendEvent( 'Book', 'Click (' + parent + ')', action);
    // Redirect to Target URL after .25 second delay
    setTimeout(function () { window.location = href; }, 250);
});

// -------------------------------------------

// Google Analytics: lookup brands by url or short code (e.g. wdw)
function lookupBrand(data, output) { // data = URL or code, output = url/code/name
    // Use fall-through for multiple matches
    switch (data) {
        // ------------------
        // Disneyland Paris
        case 'dlp':
        case '/disneyland-paris/':
            // Return output
            if (output == 'url') { return '/disneyland-paris/' }
            if (output == 'code') { return 'dlp' }
            if (output == 'name') { return 'Disneyland Paris' }
        // ------------------
        // Walt Disney World
        case 'wdw':
        case '/walt-disney-world/':
            // Return output
            if (output == 'url') { return '/walt-disney-world/' }
            if (output == 'code') { return 'wdw' }
            if (output == 'name') { return 'Walt Disney World' }
        // ------------------
        // Disney Cruise Line
        case 'dcl':
        case '/disney-cruise-line/':
            // Return output
            if (output == 'url') { return '/disney-cruise-line/' }
            if (output == 'code') { return 'dcl' }
            if (output == 'name') { return 'Disney Cruise Line' }
        // ------------------
        // Walt Disney Travel Company
        default:
            // Return output
            if (output == 'url') { return '/' }
            if (output == 'code') { return 'wdtc' }
            if (output == 'name') { return 'Walt Disney Travel Company' }
    }
}

// -------------------------------------------

// Google Analytics: track activity in the chrome/offcanvas when switching between brands
$('#chrome dfn a, nav li.sublink.brand a').click(function (event) {
    // Pause
    event.preventDefault();
    // Check current brand
    var currentbrand = $('body').attr('data-brand');
    // Get clicked destination URL
    var targeturl = $(this).attr('href');
    // Determine target brand
    var targetbrand = lookupBrand(targeturl, 'code');
    // No action from portfolio homepage, or if switching from/to the same brand
    if ((currentbrand == 'wdtc') || (currentbrand == targetbrand)) {
        window.location = targeturl;
    } else {
        // Otherwise build trackEvent data
        var portfolio = lookupBrand(currentbrand, 'name') + ' to ' + lookupBrand(targetbrand, 'name');
        // Track in GA
        GA.sendEvent( 'Chrome', 'Portfolio', portfolio);
        // Redirect to target URL after .25 second delay
        setTimeout(function () { window.location = targeturl; }, 250);
    };
});

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Manage modal dialog (generic)

// User dismissable dialog; false by default
var dismissable = false;

// Show bouncing arrow for scrollable content
function bounce() {
    $('#dialog p.arrow').show().delay(4000).fadeOut();
}

// Check if content height exceeds viewable container height (mobile only)
function addScroll(dialog) {
    // Mobile only
    if (mobile || $(dialog).hasClass('wide')) {
        // Get heights of container/content
        var container = $('#dialog').height(); // Viewable height of #dialog
        var content = $(dialog + ' > div').height(); // Total height of content
        // If content exceeds container then make scrollable
        if (content > container) {
            $('#dialog').addClass('scrollable');
            bounce();
            fixBody(true);
        }
    }
}
// Set info modal onclick event
$("a[id$='info-modal-trigger']").each(function (i, el) {
    $(el).click(function () {
        infoModal(el);
    })
})
//Show info modals
function infoModal(modalLink) {
    var gaEvent = $(modalLink).attr('data-ga-event').split("|");
    dismissable = true;
    $('#dialog').addClass('dismissable information');
    GA.sendEvent( gaEvent[0], gaEvent[1], gaEvent[2]);
    modal($(modalLink).attr('data-modal-id'), true);
}
// Show modal with delay to allow for fixBody() to complete
function modal(dialog, scrollable) {
    // Show overlay
    $(dialog).removeClass('hide').siblings('.modal').addClass('hide');
    $('#overlay').show();
    // Show modal
    $('#interstitial').show();
    // If content may be scrollable, check relative heights
    if (scrollable) { addScroll(dialog); }
}

// Collapse modal dialog and remove class
function collapse() {
    $('#overlay').hide();
    $('#interstitial').hide();
    $('#dialog').removeClass();
    $('.modal').addClass('hide');
    fixBody(false);
}

// Dismiss dialog
function dismiss() {
    if (dismissable) {
        collapse();
        dismissable = false;
    };
}

// Listen for esc press, or users click on overlay outside of modal dialog
$(function () {
    $('#modal').click(function (event) { event.stopPropagation(); });
    $('#interstitial, .cancel, .close, .close:before').click(dismiss);
    $(document).keyup(function (event) { if (event.keyCode == 27) { dismiss(); } });
});

// -------------------------------------------

// Manage modal dialog (specific)

// Setup variables to be populated by brand level functions
var character;
var nickname;
var fact;

// -------------------------------------------

// Delay submission by default to allow image to load
var submit = false;

// Setup variables for form submission
var form;
var delay;

// Wait for image to load before submitting form
function submitForm() {
    // Re-check if we can submit form every 0.5 seconds
    if (submit == false) { setTimeout(function () { submitForm() }, 500); }
    // Submit form when flag equals true (e.g. image is loaded or 3 seconds has elapsed)
    else { setTimeout(function () { form.submit(); }, delay); }
}

// -------------------------------------------

// Display intermission message; user cannot dismiss
function intermission(type) { // Optional 'type' parameter for booking message
    // Set body to posiiton:fixed to force toolbars
    fixBody(true);
    // Set character/nickname/fact
    intermissionData();
    // Set brand
    var brandname
    switch (brand) {
        case 'dlp': brandname = 'Disneyland<sup>&reg;</sup> Paris'; break;
        case 'wdw': brandname = 'Walt Disney World<sup>&reg;</sup>'; break;
        case 'dcl': brandname = 'Disney Cruise Line<sup>&reg;</sup>'; break;
    }
    // Setup messages
    $('#intermission h2').html(literal('intermission.title', { nickname: nickname }));
    $('#intermission h3').html(literal('intermission.message', { brandname: brandname }));
    $('#intermission p:first').html(literal('intermission.did-you-know') + fact);
    // Load image
    $('#intermission .image').addClass(character);
    $('#intermission .image img').attr('src', '/images/modal/' + character + '.png');
    // Listen for callback or give up after 3 seconds
    $('#intermission .image img').load(function () { submit = true; });
    setTimeout(function () { submit = true; }, 3000); // Fallback
    // Alternative message for payment/booking
    if (type == 'booking') { $('#intermission h3').html(literal('intermission.payment')); }
    // Add intermission class to dialog
    $('#dialog').addClass('intermission has-image');
    // Show dialog
    modal('#intermission', false);
    // Dialog is not dismissable
    dismissable = false;
}

// -------------------------------------------

// Display warning message; user can dismiss
function warning(message, advisory) {
    // Dialog is dismissable
    dismissable = true;
    $('#dialog').addClass('dismissable');
    // Set class and content
    $('#dialog').addClass('warning');
    $('#warning h3').html(message); $('#warning p:first').html(advisory);
    // Show dialog
    modal('#warning', true);
}

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Generic

// Sliding panel for Disney deal terms, etc.
if ($('#terms').length) { $('.footer a').click(function () { $('#terms').slideDown(); }); };

// Clicking wrapper also clicks button; stopPropagation on input to prevent loop
$('span.button,span.cancel,span.disabled').click(function () { $(this).find("input").click(); }); $('span > input').click(function (event) { event.stopPropagation(); });

// Scroll to corresponding anchor on page; applies to any <a> with 'anchor' attribute
$('a[anchor]').click(function () { jQuery.scrollTo($(this).attr('anchor'), 400, { offset: -10, axis: 'y' }); });

// Scroll to terms in footer from sample itinerary package
$('.synopsis small a').click(function () { jQuery.scrollTo('.footer', 400, { offset: -10, axis: 'y' }); });

//Read more/less t&c's button
$("a.show-hide").click(function () {
    var txt = $("#terms").is(':visible') ? literal('more') : literal('less');
    $(".show-hide").text(txt);
    $('#terms').slideToggle(200);
});

$("a.show-hide-generic").click(function () {
    // get panel to show and hide
    var pnlId = $(this).attr("data-panel");
    var flagUseMoreLess = $(this).attr("data-panelusemoreless");

    if (flagUseMoreLess == "1" || flagUseMoreLess == "true") {
        var txt = $("#" + pnlId).is(':visible') ? literal('more') : literal('less');
        $(this).text(txt);
    }

    $("#" + pnlId).slideToggle(200);
});

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Bookflow

// -------------------------------------------

/* Popups */

// Manage popup for luggage
function luggage() {
    GA.sendEvent( 'Book', 'Enquiry', 'Hold Luggage');
    warning(literal('hold-luggage-title'), literal('hold-luggage-message') );
};

// -------------------------------------------

// Select hotel
$('.accommodation .images img').click(function () { $(this).closest('.accommodation').find('input').click(); });

// Select room
$('.errata .expand a').click(function (event) { event.preventDefault(); $('.errata .expand').hide(); $('.errata .details').show(); GA.sendEvent( 'Book', 'Enquiry', 'Errata'); });

// Itinerary
$('.itinerary .luggage a').click(function (event) { event.preventDefault(); luggage(); });

// Passenger details: check input on DoB field on click, then clear and remove 'grey' class if value is DD/MM/YYYY
$('#passengers .details input[name*="dob"]').focus(function () { if ($(this).val() == 'DD/MM/YYYY') { $(this).val(''); }; $(this).removeClass('grey'); });

// -------------------------------------------

//Suppressed as part of DEV-1137 GDPR: First Layer Fair Privacy Notice

// Toggle the T&C checkboxes on country in contact details forms
//function toggleCountrySpecificContent(country, locationChanged) {
//    if (locationChanged) { $("label[for='group']").removeClass('error'); };
//    var selectedCountry = country.toLowerCase();
//    var elements = $("[data-location~='" + selectedCountry + "']");
//    if (elements.length > 0)
//    {
//        $("[data-location!='" + selectedCountry + "'][data-location]").css('display', 'none');
//        $("[data-location~='" + selectedCountry + "']").show();
//    } else {
//        $("[data-location='']").show();
//        $("[data-location][data-location!='']").css('display', 'none');
//    }
//}

// Check retained selected country value on page refresh to ensure country-specific content remains visible/hidden
//if ($("#country").length) {
//toggleCountrySpecificContent($('#country').find(':selected').text(), false);
//};

// Check country and advise that payment is in local currency
$('.contact #country').change(function () {
    var locale = $('#country').attr('data-locale');
    var country = $('#country').val();
//    toggleCountrySpecificContent(country, true);
    if (locale == "gbr" && country != 'United Kingdom') { warning(literal('payment-pounds-title'),literal('payment-pounds-message') ); GA.sendEvent( 'Alert', 'Checkout', 'GBP Payment'); }
    else if ((locale == "irl" || locale == "eur" || locale == "deu" || locale == "esp") && (country == 'Bulgaria' || country == 'Croatia' || country == 'Czech Republic' || country == 'Denmark' || country == 'Hungary' || country == 'Poland' || country == 'Romania' || country == 'Sweden' || country == 'United Kingdom')) {
        warning(literal('payment-euros-title'), literal('payment-euros-message')); GA.sendEvent( 'Alert', 'Checkout', 'EUR Payment');
    }
});
// -------------------------------------------

// Non-EU
$('.billing a').click(function () { warning(literal('non-eu-title'), literal('non-eu-message')); GA.sendEvent( 'Book', 'Enquiry', 'European Union Only'); });

// Track marcomms opt-in/outs in Google Analytics
$('#contact .updates input').change(function () {
    // Opt-in/out
    if ($('input:checked').val() == 'Y') { GA.sendEvent( 'Marketing', 'Booking', 'Opt-In'); }
    if ($('input:checked').val() == 'N') { GA.sendEvent( 'Marketing', 'Booking', 'Opt-Out'); }
});

// -------------------------------------------

// Hotels

// Clicking anywhere on a hotel clicks through to details, not just the link
$('.resort img').click(function () { window.location = $(this).parent().find('a').attr('href'); })

// Track outbound clicks on TripAdvisor links
$('.resort p.reviews').click(function (event) {
    // Prevent event from bubbling up the DOM tree
    event.preventDefault();
    event.stopImmediatePropagation();
    // Get TripAdvisor link
    var link = $(this).attr('data-review');
    // Track in GA
    GA.sendEvent( 'Outbound Link', 'TripAdvisor', link );
    // Redirect to Target URL after .25 second delay
    setTimeout(function () { window.open(link, '_blank') }, 250);
});

// -------------------------------------------

// Deals
$('.offer img').click(function () { window.location = $(this).parent().find('a').attr('href'); })

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// Ensure interstitial and overlay are hidden on unload in case user goes 'Back' in browser; bind 'pagehide' for iOS
$(window).unload(function () { collapse(); });
$(window).bind('pagehide', function () { collapse(); });

// -------------------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function () {

    // -------------------------------------------------------------------------------------------------------------------

    // Generic

    // Track outbound click in current window (target=_self)
    $('a[href^="http"][target!="_blank"]').click(function (event) {
        // Pause click
        event.preventDefault();
        // Track outbound click
        outboundClick($(this), false);
    });

    // Track outbound click in new window (target=_blank)
    $('a[href^="http"][target="_blank"]').click(function (event) {
        // Track outbound click
        outboundClick($(this), true);
    });

    // -------------------------------------------------------------------------------------------------------------------

    // On load

    // Ensure interstitial and overlay are hidden on load; bind 'pageshow' for iOS; exclude interstitial
    if (!$('body').hasClass('interstitial')) { collapse(); $(window).bind('pageshow', function () { collapse(); }); }

    // Log errors in Google Analytics
    logErrors();

    // -------------------------------------------------------------------------------------------------------------------

    // Homepage and WDW Ticket Store

    // Setup billboard carousel on portfolio/brand homepages
    if($('body').hasClass('homepage')) {
        // Setup
        billboard = $('.billboard');
        initialiseBillboard();
    }

    // Setup billboard carousel on content pages e.g. WDW Ticket Store
    if($('#body').hasClass('carousel')) {
        // Setup
        billboard = $('.billboard');
        initialiseBillboard('contentPage');
    }

    if($('#body.carousel #banner-message').length != 0) {
        $('.billboard').addClass('top-border-radius');
    }

    // -------------------------------------------

    // Submit form on FAQs drop menu selection
    $('.faq #category').change(function () { $('#form').submit(); });

    if($('.reopening-hotels-list').length) {
        $('.reopening-hotels-list').accordion({heightStyle: "content", active: false, collapsible: true})
    }
    
    if ($('.ytplayer, #ytplayer').length ) {
        var tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        var player;
    }
    addVariantToDataLayer(typeof tmsPageVariant !== 'undefined' ? tmsPageVariant : null);

    addPricesToDataLayer();

    $('.average-price').click(showAveragePriceModal)
    
    // Find all input elements within the section with id "payment-method"
    $('#payment-method .type').one('click', function () {
        var input = $(this).find('input');
        GA.sendEvent('Payment', 'Selection', input.attr('name'));
    });

    // -------------------------------------------

});

function onYouTubeIframeAPIReady() {
    $('.ytplayer, #ytplayer').each(function(index, videoContainer){
        ytPlayers[index] =  new YT.Player(videoContainer.id, {
            events: {
                'onReady': function(event) { return onPlayerReady(event, videoContainer, index) }
            }
        });
    })
}

function onPlayerReady(event, videoContainer, index) {
    var keyFrame = $(videoContainer).parent().siblings('.keyframe'); 
    $('#play', keyFrame).click(function () {
        GA.sendEvent('Marketing', 'Click', 'Play Video');
        $(keyFrame).hide();
        ytPlayers[index].playVideo();
    });
}

// -------------------------------------------
//planning
// Check date of birth
var validDoB = function (day, month, year, ageError) {
    // Get now
    var now = moment();
    // Build DoB
    day = parseInt($(day).val());
    month = parseInt($(month).val());
    year = parseInt($(year).val());
    // Do we have three valid date values?
    if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
        // Convert to moment
        var dob = moment([year, month - 1, day]);
        // Is this a valid date of birth?
        if (dob.isValid()) {
            // Does this DoB make the guest less than 18 years old?
            if (dob.isAfter(now.subtract(18, 'y'))) { warning(ageError[0], ageError[1]); }
        }
    }
};

//Signup validation
if ($("#body.signup").length) {
    //initialization
    var ageError = [literal('over-18-title'), literal('over-18-message')];
    var day = '#body.signup #day', month = '#body.signup #month', year = '#body.signup #year';

    // Check valid DoB
    $(day + ", " + month + ", " + year).change(function () {
        validDoB(day, month, year, ageError);
    });

    // Check if this is the confirmation screen and fire GA event
    if ($("#body.signup").attr('data-step') == 'confirmation') { GA.sendEvent( 'Marketing', 'Signup', 'Opt-In'); }
};


// -------------------------------------------

//onetrust

$('#onetrust-pc-btn-handler').click(function () {
    window.Optanon.ToggleInfoDisplay();
});

if ($('.carousel-cards').length) {
    fetchSlick(function(){
        $('.carousel-cards').each( function(index, scrollableContainer) {
            var numberOfSlides = $(this).closest('.carousel-narrow').length ? 2 : 3;
            if (numberOfSlides === 2) {
                $(scrollableContainer).on('init', function () {
                    var fixActiveCard = $('.carousel-card.slick-active').eq(2)
                    fixActiveCard.css('transition', 'none').removeClass('slick-active')
                    setTimeout(function () {
                        fixActiveCard.css('transition', '1s opacity')
                    }, 1)
                })
                $(scrollableContainer).on('beforeChange', function () {
                    setTimeout(function () {
                        $('.carousel-card.slick-active').eq(2).removeClass('slick-active')
                    }, 1)
                })
            }
            $(scrollableContainer).slick({
                dots: true,
                speed: 500,
                centerMode: $(scrollableContainer).find('.carousel-card').length > 3,
                centerPadding: '50px',
                swipe: true,
                slidesToShow: numberOfSlides,
                slidesToScroll: 1,
                appendArrows: $('.slider-nav', $(this.parentElement)),
                appendDots: $('.slider-nav', $(this.parentElement)),
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        swipe: false,
                        
                    }
                }, {
                    breakpoint: 600,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: true,
                        centerPadding: '30px',
                    }
                }]
            });
        })    
    })
}


function fetchSlick(callback) {
    if (!$().slick) {
        $.getScript(getJsPath() + "libs/slick-1.6.0.min.js", callback);
    } else {
        callback();
    }
}


//--------------------------------------------
//disney plus

if ($('#terms').length) { $('#disneyplus').click(function () { $('#terms').slideDown(); jQuery.scrollTo($('#terms'), 400, { offset: -10, axis: 'y' });}); };


$('#agents #new-password').keyup(function(){
    validateAgentPassword(this.value)
});

$('#agents #new-password').blur(function(){
    validateAgentPassword(this.value, true)
});

$('#agents span.button input[value="Update Password"]').attr('disabled', true).parent().toggleClass('disabled',true);

function showDataAttributeWarning() {
    if ($(this).attr('data-warning')) {
        var message = $(this).attr('data-warning').split('|');
        warning(message[0], message[1]);
    }
}

$('#instalments-show-info-trigger').click(showDataAttributeWarning);
 
$('#instalment-input-amount').focus(function(){
    $('#instalment-own-amount').prop('checked',true);
    $(this).removeClass('error');

});

$('#instalments-make-payment').click(function(){
    var isAmountValid = true;
   
    if ($('#instalment-own-amount').prop('checked')) {
        var amountValue = $('#instalment-input-amount').val();
        if (!amountValue || (amountValue < 10 && paymentBalance >= 10) || isNaN(amountValue)) {
            warning(literal('instalment-validation-minimum-title', {currencySymbol}), literal('instalment-validation-minimum-message', {currencySymbol}));
            isAmountValid = false;
        } else if (amountValue > paymentBalance) {
            warning(literal('instalment-validation-maximum-title'), literal('instalment-validation-maximum-message'));
            isAmountValid = false;
        }
    }
    
    if (isAmountValid) {
        SetInstalmentPaymentAnalytics();
        $('form').submit();
    } else {
        $('#instalment-input-amount').addClass('error');
    }
    
})

function SetInstalmentPaymentAnalytics() {
    var instalmentPaymentTypeAndAmount = $('input[name=instalment-option]:checked', '#form').val();
    var paymentType = instalmentPaymentTypeAndAmount.split("~")[0];

    var amount = instalmentPaymentTypeAndAmount.split("~")[1];

    if (paymentType == 'Custom') {
        amount = $('#instalment-input-amount').val();
    }

    var details = paymentType.concat("|", leadTimeDays, "|", amount, "|", paymentBalance, "|", bookingReference);

    GA.sendEvent( 'InstalmentPayment', 'Type', paymentType);
    GA.sendEvent( 'InstalmentPayment', paymentType, 'Amount', amount);
    GA.sendEvent( 'InstalmentPayment', 'Detail', details);
}

function addVariantToDataLayer(variant){
    var formatVariant = {
        "Expired" :'Timeout',
        "NoAllocations" :'No Allocation Flights',
        "OutOfRange" :'Flights Out of Range',
        "NoFlights" :'No Flights (11+ Months)',
        "Hotel" :'Hotel Unavailable',
        "Eurostar" :'Eurostar Unavailable',
        "Flights" :'Flights Unavailable',
        "SelfDrive" :'Self Drive Unavailable',
        "Seats" :'Seats Unavailable',
        "Payment" :'Payment Failure',
        "Booking" :'Booking Failure',
    }
    window.dataLayer.page.variant = formatVariant[variant] || variant;    
}

function addPricesToDataLayer() {
    if (typeof originalPrice !== 'undefined') {
        window.dataLayer.product.originalPrice = originalPrice;
    }

    if (typeof price !== 'undefined') {
        window.dataLayer.product.price = price;
    }
}

function showAveragePriceModal() {
    warning(literal('modals.averagePrice.title'), literal('modals.averagePrice.message'))
}

